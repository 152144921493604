<template lang="pug">
lot-card(
  v-if="contract"
  show-calc-form
  type="contract"
  :lot="contract"
)
  template(v-slot:title)
    lot-name(:lot="contract")
  template(v-slot:template-info)
    template-info(:templates="contract?.contract?.templates || []")
  template(v-slot:lot-item__status)
  template(v-slot:price)
    cost-modifier(v-model="contract.contract" :disabled="roleReadOnly")
  template(v-slot:status)
    contract-status-selector(
      v-model="contract"
      :disabled="roleReadOnly"
    )
  template(v-slot:responsible-person)
    manager-selector(
      v-model="contract.contract.responsiblePerson"
      label="Ответственный"
      :show-set-me-link="!isTmManager"
      :persistent="false"
      :teleported="false"
      :disabled="roleReadOnly"
      @set-manager="selectResponsible"
    )
  template(v-slot:dates)
    contract-item-dates(:contract="contract")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { updateContractCommon } from "~/utils/api/searchRequests";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

import type { PropType } from "vue";
import type { ManagerI } from "~/stores/auth/UserInterface";
import type { ContractItemInterface } from "~/stores/search/LotItemInterface";

import LotCard from "~/components/cards/LotCard.vue";
import TemplateInfo from "~/components/mainStreamPage/lotItem/TemplateInfo.vue";
import CostModifier from "~/components/pages/contracts/card/CostModifier.vue";
import ManagerSelector from "~/components/selector/ManagerSelector.vue";
import ContractStatusSelector from "~/components/selector/tenderStatuses/ContractStatusSelector.vue";
import LotName from "~/components/cards/modules/LotName.vue";
import ContractItemDates from "~/components/pages/contracts/card/ContractItemDates.vue";

export default defineComponent({
  name: "ContractItem",
  components: {
    ContractItemDates,
    LotName,
    LotCard,
    TemplateInfo,
    CostModifier,
    ManagerSelector,
    ContractStatusSelector,
  },
  props: {
    contract: {
      type: Object as PropType<ContractItemInterface>,
      default: () => ({}),
    },
  },
  setup(props) {

    const { roleReadOnly, isTmManager } = useUserAccess();

    function selectResponsible(p: { newValue: ManagerI | null, oldValue: ManagerI | null }) {
      updateContractCommon(props.contract!.contract.id, { responsiblePerson: p.newValue ? "/api/users/" + p.newValue.id : null })
        .catch(() => props.contract!.contract.responsiblePerson = p.oldValue)
    }

    return {
      isTmManager,
      roleReadOnly,
      selectResponsible,
    };
  },
});
</script>

<style scoped lang="scss">
</style>
