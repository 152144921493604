<template lang="pug">
.item-cost__title
  span Цена контракта
  ui-icon(
    v-if="!disabled && !showInput"
    clickable
    :size="16"
    :icon="UiIconNames.Icon_Edit"
    @click="showInput = true"
  )
span.lot-card__main-cost-value(v-if="!showInput")
  | {{ costFormatter(contract.cost.original.currency.alpha).format(contract.cost.original.cost || 0) }}
.contract-cost-edit(v-else)
  cost-input(v-model="modifiedCost" placeholder="Цена контракта")
  span.currency {{ contract.cost.original.currency.alpha }}
  ui-icon.icon-blue(
    clickable
    :size="16"
    :icon="UiIconNames.Icon_CloseBold"
    @click="undoCost"
  )
  mini-loader(v-if="loader")
  ui-icon.icon-blue(
    v-else
    clickable
    :size="16"
    :icon="UiIconNames.Icon_CheckUnderline"
    @click="editCost"
  )
.item-cost__title.item-cost_original-price(v-if="contract?.cost?.converted?.cost")
  span По текущему курсу:
  span(style="color: var(--main-color-black); margin-left: 8px;")
    | ~ {{ costFormatter(contract.cost.converted.currency.alpha).format(contract.cost.converted.cost) }}
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { costFormatter } from "@/utils/formatter/costFormatter";
import { useVModel } from "@vueuse/core";
import { updateContractCommon } from "@/utils/api/searchRequests";

import UiIconNames from "@/components/ui/icon/UiIconNames";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import CostInput from "@/components/ui/form/input/CostInput.vue";
import MiniLoader from "@/components/ui/loader/MiniLoader.vue";

import type { PropType } from 'vue';
import type { TmLotContractInterface } from "@/stores/search/LotItemInterface";

export default defineComponent({
  name: "CostModifier",
  components: {
    UiIcon,
    CostInput,
    MiniLoader,
  },
  methods: {
    costFormatter,
  },
  emits: [
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Object as PropType<TmLotContractInterface>,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const loader = ref(false);
    const showInput = ref(false);

    /** цена контракта */
    const contract = useVModel<TmLotContractInterface>(props, 'modelValue', context.emit);

    /** редактированная цена контракта */
    const modifiedCost = ref(contract.value?.cost?.original?.cost || 0);

    function undoCost() {
      showInput.value = false;
      modifiedCost.value = contract.value.cost?.original?.cost || 0;
    }

    function editCost() {
      loader.value = true;
      const oldCost = Number(contract.value.cost?.original?.cost || 0);

      const data = {
        cost: Number(modifiedCost.value || 0),
        currency: contract.value.cost.original?.currency.id,
      }

      updateContractCommon(contract.value.id, data)
        .then((c: TmLotContractInterface) => {
          contract.value.cost = c.cost;
          showInput.value = false;
        })
        .catch(() => contract.value.cost.original!.cost = oldCost)
        .finally(() => loader.value = false)
    }

    return {
      loader,
      showInput,
      contract,
      modifiedCost,
      UiIconNames,
      undoCost,
      editCost,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/lots/lotCardCost";

.icon-blue {
  color: var(--main-color-blue);
}

.contract-cost-edit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;

  .currency {
    font-size: 13px;
    color: var(--lot-card__color__info-title);
  }
}
</style>
