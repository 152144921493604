<template lang="pug">
list-wrapper(
  :info="info"
  :count="data.length || 0"
  no-data-text="Контракты не найдены"
  result-text="Кол-во найденных контрактов"
  @reload="$emit('reload')"
  @load-next-part="$emit('loadNextPart')"
)
  contract-item(
    v-for="item of data"
    :key="item.lotId"
    :contract="item"
  )

contract-dates-picker-dialog(v-if="showCalendarDialog && !!contract")
contract-date-sign-dialog(v-if="showSignDialog && !!contract")
contract-dates-info-dialog(v-if="showDatesInfoDialog && !!contract")
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent } from "vue";
import { getEntityListInfoSelective } from "@/utils/getters/defaultRequestInfo";
import { useContractsDialogsOpener } from "~/use/contracts/useContractsDialogsOpener";

import ContractItem from "~/components/pages/contracts/list/ContractItem.vue";
import ListWrapper from "@/components/mainStreamPage/metaInfo/ListWrapper.vue";

import type { PropType } from "vue";
import type { MainStreamListInfo } from "@/utils/getters/defaultRequestInfo";
import type { ContractItemInterface } from "@/stores/search/LotItemInterface";

export default defineComponent({
  name: "ContractsList",
  components: {
    ListWrapper,
    ContractItem,
    ContractDatesInfoDialog: defineAsyncComponent(() =>
      import("@/components/mainStreamPage/lotItem/dialogs/ContractDatesInfoDialog.vue")
    ),
    ContractDatesPickerDialog: defineAsyncComponent(() =>
      import("@/components/mainStreamPage/lotItem/dialogs/ContractDatesPickerDialog.vue")
    ),
    ContractDateSignDialog: defineAsyncComponent(() =>
      import("@/components/mainStreamPage/lotItem/dialogs/ContractDateSignDialog.vue")
    ),
  },
  emits: [
    'reload',
    'loadNextPart',
  ],
  props: {
    data: {
      type: Array as PropType<ContractItemInterface[]>,
      default: () => [],
    },
    info: {
      type: Object as PropType<MainStreamListInfo>,
      default: getEntityListInfoSelective,
    },
  },
  setup() {

    const {
      contract,
      showSignDialog,
      showCalendarDialog,
      showDatesInfoDialog,
    } = useContractsDialogsOpener();

    return {
      contract,
      showSignDialog,
      showCalendarDialog,
      showDatesInfoDialog,
    };
  },
});
</script>

<style scoped lang="scss">
</style>
