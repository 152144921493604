<template lang="pug">
.contract-item__dates
  .show-history(@click="openDatesInfoDialog(contract)") История перевода контракта по датам
  .item-date
    .item-date__block
      .item-date__title Плановая дата / Фактическая дата
      .item-date__date
        span {{ formatDateDMY(contract?.contract.dates?.datePlan) }}
        ui-icon(
          v-if="!roleReadOnly"
          clickable
          :icon="UiIconNames.Icon_Edit"
          @click="openCalendarDialog(contract)"
        )
      .item-date__date
        span {{ formatDateDMY(contract?.contract.dates?.dateFact) }}
        ui-icon(
          v-if="!roleReadOnly"
          clickable
          :icon="UiIconNames.Icon_Edit"
          @click="openCalendarDialog(contract)"
        )
    .item-date__block
      .item-date__title Дата заключения контракта
      .item-date__date
        span {{ formatDateDMY(contract?.dateContractSign) }}
        ui-icon(
          v-if="!roleReadOnly"
          clickable
          :icon="UiIconNames.Icon_Edit"
          @click="openDateSignDialog(contract)"
        )
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formatDateDMY } from "@/utils/formatter/dateFormatter";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useContractsDialogsOpener } from "~/use/contracts/useContractsDialogsOpener";

import type { PropType } from "vue";
import type { ContractItemInterface } from "@/stores/search/LotItemInterface";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "ContractItemDates",
  components: {
    UiIcon,
  },
  methods: {
    formatDateDMY,
  },
  props: {
    contract: {
      type: Object as PropType<ContractItemInterface>,
      default: () => ({}),
    },
  },
  setup() {

    const { roleReadOnly } = useUserAccess();

    const {
      openDateSignDialog,
      openCalendarDialog,
      openDatesInfoDialog,
    } = useContractsDialogsOpener();

    return {
      openDateSignDialog,
      openCalendarDialog,
      openDatesInfoDialog,
      roleReadOnly,
      UiIconNames,
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/lots/lotItemDates";

.contract-item__dates {
  display: flex;
  flex-flow: column;
  gap: 8px;

  .show-history {
    font-size: 13px;
    color: var(--main-color-blue);
    cursor: pointer;
  }
}

.item-date__date {
  align-items: center;

  svg {
    color: var(--main-color-blue);
  }
}
</style>
