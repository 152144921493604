import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { getEntityListInfoSelective } from "@/utils/getters/defaultRequestInfo";
import {
  commonSupplemental,
  getEntitiesList,
  cancelRequest,
  prepareContractData
} from "@/utils/api/searchRequests";
import useContractsStore from "@/stores/contracts/useContractsStore";
import useSearchStore from "@/stores/search/useSearchStore";
import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";

import type { MainStreamListInfo } from "@/utils/getters/defaultRequestInfo";
import type { PreFiltrationFormContractI } from "@/utils/getters/defaultFilters";
import type { ContractItemInterface } from "@/stores/search/LotItemInterface";
import { checkTagFiltersOnActuality } from "~/utils/searchForm/searchFormWorker";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";

export function useContractList() {

  const data = ref<ContractItemInterface[]>([]);
  const info = ref<MainStreamListInfo>(getEntityListInfoSelective());

  const searchStore = useSearchStore();
  const interfaceStore = useInterfaceSettingsStore();

  const contractsStore = useContractsStore();

  const { showWarning } = useNotificationsStore();

  const {
    pageStatuses,
    preFiltrationForm,
  } = storeToRefs(contractsStore)

  const {
    needReload,
  } = storeToRefs(searchStore)

  const {
    selectedTemplatesIds,
  } = storeToRefs(interfaceStore.functions.saveFiltersOnRedirect ? searchStore : contractsStore);

  const {
    setTab,
    updatePages,
  } = contractsStore;

  function fetchContractsList(fetchData: any) {
    getEntitiesList(info, fetchData, "search/contracts")
      .then((newData) => {
        // @ts-ignore
        data.value = data.value.concat(newData);
      })
      .catch(() => {})
  }

  function fetchContractsSupplemental(fetchData: any) {
    commonSupplemental(info, fetchData, "search/contracts/supplemental")
  }

  function reset() {
    data.value = [];
    info.value = getEntityListInfoSelective();
  }

  // inner use only
  function fetchEntity(preFiltrationForm: PreFiltrationFormContractI, withSupplemental: boolean) {
    const fetchData = prepareContractData(selectedTemplatesIds.value, pageStatuses.value, preFiltrationForm)
    fetchContractsList(fetchData)
    if (withSupplemental) fetchContractsSupplemental(fetchData)
  }

  // подгрузка данных
  function updateTemplateOutput() {
    fetchEntity(preFiltrationForm.value, false);
  }

  // поиск контрактов
  function fetchContracts() {
    updatePages()
    cancelContractsRequests()
    reset()
    fetchEntity(preFiltrationForm.value, true)
  }

  function cancelContractsRequests() {
    cancelRequest(info)
  }

  watch(needReload, (value) => {
    if (value) {
      const value = checkTagFiltersOnActuality(preFiltrationForm.value.tagIds, searchStore.tags)
      if (value) {
        preFiltrationForm.value.tagIds = value
        fetchContracts()
        showWarning('Список контрактов был перезапрошен, так как фильтр по тегам содержал неактуальные значения', 4000, 'Внимание')
      }
      needReload.value = false;
    }
  })

  return {
    data,
    info,
    pageStatuses,
    preFiltrationForm,
    selectedTemplatesIds,
    setTab,
    fetchContracts,
    updateTemplateOutput,
    cancelContractsRequests,
  }
}
