import { ref } from "vue";
import type { ContractItemInterface } from "~/stores/search/LotItemInterface";

const contract = ref<ContractItemInterface>();
const contractUpdating = ref(false);

const datesOnStatusChange = ref(false);

const showSignDialog = ref(false);
const showCalendarDialog = ref(false);
const showDatesInfoDialog = ref(false);

export function useContractsDialogsOpener() {

  function openDateSignDialog(c: ContractItemInterface) {
    contract.value = c;
    showSignDialog.value = true;
  }

  function openCalendarDialog(c: ContractItemInterface, onStatus = false) {
    contract.value = c;
    datesOnStatusChange.value = onStatus;
    showCalendarDialog.value = true;
  }

  function openDatesInfoDialog(c: ContractItemInterface) {
    contract.value = c;
    showDatesInfoDialog.value = true;
  }

  return {
    contract,
    contractUpdating,
    datesOnStatusChange,
    showSignDialog,
    showCalendarDialog,
    showDatesInfoDialog,
    openDateSignDialog,
    openCalendarDialog,
    openDatesInfoDialog,
  }
}
